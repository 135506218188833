import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import Measure from 'react-measure'
import { AutoSizer } from 'react-virtualized'
import { branch } from 'recompose'
import {
  composeWithDisplayName,
  mergeStyleIfExist
} from '../reactcomponents/hocs'

const styleAutoHeight = {height: 'auto'};

const withStyleHeight = Component => ({ height, style, ...props }) => (
  <Component
    style={mergeStyleIfExist(
      style,
      styleAutoHeight
      // useMemo(() => ({height: height + 'px'}), [height])
    )}
    {...props}
  />
)

const withAutoSizerHeightBase = Component => ({
  height,
  onResize,
  ...props
}) => (
  <AutoSizer disableWidth onResize={onResize}>
    {({ height }) => <Component height={height} {...props} />}
  </AutoSizer>
)

const withCustomMeasureRef = Component => ({ onResize, ...props }) => {
  const measureRef = useRef(null)
  useEffect(() => {
    if (measureRef.current) {
      const { clientHeight: height, scrollHeight } = measureRef.current
      // if (process.env.NODE_ENV !== 'production') {
      //   console.log(
      //     'dbg: measure:',
      //     height, scrollHeight, scrollHeight > height
      //   )
      // }
      if (scrollHeight > height) {
        onResize({height: scrollHeight})
      }
    }
  })
  return <Component measureRef={measureRef} onResize={onResize} {...props} />
}

const withAutoSizerHeight = composeWithDisplayName(
  'withAutoSizerHeight',
  withCustomMeasureRef,
  withAutoSizerHeightBase,
  withStyleHeight
)

const withMeasureRef = Component => ({ height, ...props }) => ({
  contentRect: { bounds: { height }, scroll: { height: scrollHeight }},
  measureRef
}) => {
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('dbg: react-measure:', height, scrollHeight)
  // }
  return <Component height={scrollHeight} measureRef={measureRef} {...props} />
}

const withReactMeasureBase = childRenderer => ({ onResize, ...props }) => (
  <Measure
    bounds
    onResize={useCallback(({ bounds }) => {
      onResize(bounds)
    }, [onResize])}
    scroll
  >
    {childRenderer(props)}
  </Measure>
)

const withReactMeasureHeight = composeWithDisplayName(
  'withReactMeasureHeight',
  withReactMeasureBase,
  withMeasureRef,
  withStyleHeight
)

const PC_USE_REACT_MEASURE = true

const withMeasurableHeightBae = PC_USE_REACT_MEASURE ? withReactMeasureHeight : withAutoSizerHeight

export const withMeasurableHeight = branch(
  ({ onResize }) => typeof onResize === 'function',
  withMeasurableHeightBae
)
