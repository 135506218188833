import { darkTheme as c } from "../../components/v5/Theme"

export const HEADER_ACTIONS = `
.c3-header-actions {
  &.errand-nav.v5 {
    .actions-container {
      >li {
        border-color: ${c.lightBlack};
        &.active-blue {
          background-color: ${c.tintBlue};
        }
        &.active-red {
          background-color: ${c.tintRed};
        }
        &.active-yellow {
          background-color: ${c.tintYellow};
        }
        &[me="twt_unlike"] {
          background-color: ${c.tintRed};
        }
        &[me="twt_unretweet"] {
          background-color: ${c.tintGreen};
        }
        &[me="ins_unhide"] {
          background-color: ${c.tintBlue};
        }
        &[me="fb_unhide"] {
          background-color: ${c.tintBlue};
        }
        &[me="fb_unlike"] {
          background-color: ${c.tintBlue};
        }
        .c3-dropdown {
          div{
            &[data-placement*='bottom']:before {
              border-color: transparent transparent ${c.lightBlack} transparent;
            }
            &[data-placement*='top']:before {
              border-color: ${c.lightBlack} transparent transparent transparent;
            }
            &[data-placement*='left']:before {
              border-color: transparent transparent transparent ${c.lightBlack};
            }
            &[data-placement*='right']:before {
              border-color: transparent ${c.lightBlack} transparent transparent;
            }
          }
        }
      }
    }
  }
}`