import { darkTheme as c } from "../../components/v5/Theme"

export const ADMIN_STYLES = `
.adminWrapper {
  color: ${c.lightGrey};
  .adminEdit {
    .form-wrapper {
      input[disabled] {
        background: ${c.tintGrey};
      }
    }
  }
}
.table .table {
    background-color: ${c.midGrey};
}
.UhDNG table thead th {
    color: ${c.lightGrey};
}
.admin-heading {
  color: ${c.lightGrey};
}
.accounts-menu {
  .nav-item {
    background-color: ${c.darkGrey};
  }
}
.organization-edit {
  .panel-body {
    background-color: ${c.darkGrey};
  }
}
.admin-one-form {
  .c3-dropdown {
   .dropdown a {
      background-color: ${c.lightBlack};
    }
    &.popup-multi-select {
      .dropdown a, .dropdown .dd-header{
         &:hover {
           background-color: ${c.tintBlack};
         }
       }
    }
  }
  .attachFileBrowse {
    background-color: ${c.midGreyT2};
  }
}
.admin-form-select {
  &.form-control {
    background-color: inherit;
    color: inherit;
  }
  option {
    background-color: ${c.darkGrey};
  }
}
.label-row input {
  background-color: ${c.lightBlack};
}
.fa-question-circle {
	color: ${c.midGrey};
}
.mail-test {
  .modal-content {
    color: ${c.midGreyT2};
    .modal-header {
      border-color: ${c.tintLightBlack};
      .close {
        color: ${c.lightGrey};
      }
    }
  }
}
`