import { darkTheme as c } from "../../components/v5/Theme"

export const TAG_STYLES = `
.errand-tags {
  .errand-message-tags {
    box-shadow: -5px 0 7px 3px ${c.darkGrey};
  }
  input {
    background-color: ${c.darkGrey};
  }
}
.modal-dialog.tagitemlist .modal-content {
  background-color: ${c.lightBlack};
}
.drilldownDropdown {
  background-color: ${c.darkGrey};
}
`