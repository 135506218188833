import { darkTheme as c } from "../../components/v5/Theme"

export const ERRAND_STYLES = `
.app-conversations {
  .app-inner-content {
    .info-thickbox {
      background-color: ${c.lightBlack};
      color: ${c.lightGrey};
      &:after {
        border-top-color: ${c.lightBlack};
      }
    }
  }
  .conversation {
    &.errand-expired {
      background-color: ${c.tintRed};
    }
    &.errand-warning {
      background-color: ${c.tintYellow};
    }
    &.group-chat {
      background-color: ${c.tintPurple};
    }
    &.chat-guest {
      background-color: ${c.tintGreen};
    }
  }
}
.conversation-center {
  color: ${c.lightGrey};
}

.select-filter-with-search {
  input {
    color: ${c.lightGrey};
  }

  li {
    
    &:hover {
    background-color: ${c.midGreyT2};
    }
  }
}

.conversation-name {
  color: ${c.lightWhite};
}
.errand-box {
  border-color: ${c.lightBlack};
  .errand-add-contactcard {
    background-color: ${c.tintBlack};
    color: ${c.lightGrey};
  }
}
#container .errand-page .app-content .app-inner-content .errand-box-header.v5 .errand-box-info-text .info-section .errand-from .errand-subject {
  color: ${c.grey};
}
#container .errand-page .app-content .app-inner-content .errand-box-header.v5 .auto-classify-box {
  background-color: ${c.tintBlack};
}
.errand-box-user-info {
  background-color: ${c.lightBlack};
}
.errand-box-info-text {
  background-color: ${c.darkGrey};
  color: ${c.lightGrey};
}
.errand-box-header {
  background-color: ${c.lightBlack};
  border-color: ${c.lightBlack};
}
.errand-box-panel-trigger {
  .capsule-bar {
    background-color: ${c.lightBlack};
  }
}
.errand-box-attachments {
  border-color: ${c.lightBlack};
  background-color: ${c.midGrey};
}
.errand-box-body .toggle-switch-panel-view {
  background-color: ${c.darkGrey};
}
.errand-box-filter-bar {
  .peak-tab {
    background-color: ${c.darkGrey};
  }
  .more-block {
    background-color: ${c.darkGrey};
  }
}
.more-block {
  a {
    color: ${c.lightGrey};
  }
}
.errand-message-textbox, .errand-message-textbox-invalid-message {
  background-color: ${c.midGrey};
  border-width: 0;
  border-radius: 4px;
  box-shadow: 0px 0 6px -2px #0000004a;
  color: ${c.light};
}
.errand-message-textbox p, .errand-message-textbox-invalid-message p {
  color: ${c.lightGrey};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: .05px;
  line-height: 16px;
}

.errand-message.my {
  .errand-message-textbox, .errand-message-textbox-invalid-message {
    background-color: ${c.tintBlue};
  }
}
.errand-message.otheragent {
  .errand-message-textbox,
  .errand-message-textbox-invalid-message {
    background-color: ${c.lightAqua};
  }
}
.errand-message-meta span {
  color: ${c.lightWhite};
}
.errand-message-name {
  color: ${c.light};
}
.errand-message-actions-links {
  background-color: ${c.lightBlack};
}
.errand-message-group.external-data {
  .message-group {
    .message-data {
      background-color: ${c.tintLightBlack};
      color: ${c.lightGrey};
    }
  }
}
.errand-box-body .errand-box-reply .history-box .history-box-content {
    background: ${c.lightBlack};
    
}
.errand-box-reply {
  background-color: ${c.lightBlack};
  .block-reply-nav.chat {
    .errand-box-reply-nav {
      background-color: ${c.lightBlack};
    }
  }
  
}
.errand-box-messages {
  background-color: ${c.midGreyT2};
  .msg.errand-message.current {
    border-color: ${c.darkGrey};
  }
}
.errand-box-user-address {
	color: ${c.lightGrey};
}
.errand-channel {
  color: ${c.lightGrey};
}
.errand-box-chatmessages {
  background-color: ${c.midGreyT2};
}

.chat-toolbar {
	background-color: ${c.darkGrey};
	.cke_top {
    background-color: ${c.darkGrey};
	}
}

.errand-ready-text {
  .progress {
    background-color: ${c.lightBlack};
  }
}

.launchpad-drag-handle {
  background-color: ${c.lightBlack}
}
.launchpad-drag-handle + div {
  background-color: ${c.darkGrey}; 
}



.chatmessage-sidepanel {
  .sidepanel.chat-errand {
    .panel-header {
      background-color: ${c.lightBlack};
    }
    .panel-body {
      background-color: ${c.darkGrey};
      .content-container.client-satisfaction {
        background-color: ${c.midGreyT2};
        color: ${c.lightGrey};
      }
    }
  }
}
.v5.manual.errand {
  .errand-inner {
    .wrap-errand .tab .form-create .SplitPane {
      .v5-layout .toolbar-container {
        background-color: ${c.darkGrey};
      }
      .label-block{
        background-color: ${c.lightBlack};
      }
    }
  }
  &.popup form {
    .dropdown-item {
      background-color: ${c.lightBlack} !important;
    }
    .tag-picker-input {
      .react-tagsinput {
        background-color: ${c.lightBlack};
      }
    }
  }
  &.popup {
    .errand-titles>div {
      color: ${c.lightGrey};
    }
  }
}
.errand-preview {
  .tooltip .tooltip-inner {
    .errand-preview-subject {
      background-color: ${c.lightBlack};
    }
  }
}
.conversation-messages-count {
  background-color: ${c.red};
}
.aquired-errands-dropdown {
  background-color: ${c.darkGrey};
  color: ${c.lightGrey};
  .conversation {
    background-color: ${c.lightBlack};
  }
}
`