import { darkTheme as c } from "../../components/v5/Theme"

export const FORM_STYLES = `
.form-control {
  background-color: ${c.darkGrey};
}
.form-wrapper {
  background-color: ${c.darkGrey};
}

#DragDropKanban.dragDrop-context .board__container .board__body {
  background-color: ${c.lightBlack};
  .board__body-inner-wrap{
    background-color: ${c.lightBlack};
  }
  .board__body-inner-wrap .item {
    background-color: ${c.midGreyT2};
    border-color:${c.lightBlack};
    .item__content .item__details .v5-checkbox .label {
      color: ${c.grey};
    }
  }
}
.icon-grip:before {
  color: ${c.grey};
}

.admin-one-form .label-block .admin-text-input .input-wrap input, .admin-one-form .label-block .admin-text-input .input-wrap textarea {
    border-radius: 4px;
    width: 100%;
    transition: 0.25s;
    background-color: ${c.lightBlack};
}



.c3-dropdown {
  &.popup-multi-select {
    background-color: ${c.lightBlack};
  }
  a {
    //background-color: ${c.lightBlack};
    color: ${c.centionBlue};
  }
  .dropdown-menu {
    &.show {
      background-color: ${c.lightBlack};
      .dropdown-item{
       background-color: ${c.darkGrey};
      }
    }
  }
  
  &.call-title-input {
    a {
      color: ${c.centionBlue};
    }
  }
}
.dropdown-menu {
  background-color: ${c.darkGrey};
}
.label-block .jq-selectbox__select {
  background-color: ${c.lightBlack};
}
.label-block .jq-selectbox__dropdown {
  background-color: ${c.lightBlack};
}
.btn-white {
  background-color: ${c.midGreyT2};
  color: ${c.lightGrey};
  &.cancel {
    background-color: ${c.lightBlack};
  }
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: ${c.tintBlack};
}
textarea {
  background-color: ${c.lightBlack};
  &:focus {
    background-color: ${c.midBlack};
  }
}
.search-field {
  input {
   background-color: ${c.midGreyT2};
   &:focus {
     background-color: unset;
   }
  }
}
input.form-control {
  background-color: ${c.midGrey};
  border-color: ${c.tintBlack};
  color:${c.midBlack};
  &:focus {
    background-color: ${c.midBlack};
  }
}
.search-form {
  input {
    background-color: inherit;
    &:focus {
      background-color: unset;
    }
  }
}
.v5-checkbox.c3-square-checkbox {
  .jq-checkbox__div {
    border-color: ${c.midGrey};
  }
}
.bot-select {
  > div {
    border-color: ${c.tintBlack};
    background-color: ${c.midGrey};
  }
  [class*="indicatorSeparator"] {
    background-color: ${c.midGreyT2};
  }
  [class*="indicatorContainer"] {
    color: ${c.darkGrey}
  }
  [class*="-option"] {
    &:hover {
      background-color: ${c.midGreyT2};
    }
  }
}
`