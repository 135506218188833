import { darkTheme as c } from "../../components/v5/Theme"

export const TYPOGRAPHY_STYLES = `
  blockquote, h1, h2, h3, h4, h5, h6, p, ul, ul li {
    color: ${c.lightGrey};
  }

  i {
    color: inherit;
  }

  input {
    color: ${c.lightGrey};
    background-color: ${c.lightBlack};
    &:focus {
      background-color: ${c.midBlack};
    }
  }

  hr {
    border-color: ${c.darkGrey};
  }
  table {
    color: ${c.grey}
  }
  //icons re-color
  .icon-v5-phone {
    color: ${c.lightGrey}
  }
}`