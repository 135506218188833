import { darkTheme as c } from "../../components/v5/Theme"

export const TABLE_STYLES = `
.table {
  thead {
    th {
      border-color: ${c.tintBlack};
    }
  }
  td {
    border-color: ${c.tintBlack};
  }
}

.info-search-paginate {
  .page-link {
    background-color: ${c.darkGrey};
    border-color: ${c.tintBlack};
    outline: none;
  }
  .page-item.disabled .page-link {
    background-color: ${c.midGreyT2};
    border-color: ${c.tintLightBlack};
  }
  .page-link:focus, .page-link:hover {
    background-color: ${c.lightBlack};
    border-color: ${c.tintLightBlack};
  }
}
.bot-content {
  table {
    tr {
      border-color: ${c.tintBlack}
    }
  }
}
.c3-report-table-v5.ReactTable {
  .-pagination {
    input, select {
      background-color: ${c.lightBlack};
    }
  }
}
`