import React from "react";
import { Switch, Route, matchPath } from 'react-router';
import { Link } from 'react-router-dom';
import { branch, mapProps, withProps } from "recompose";
//dev
import {
	ADMIN
	, BACKSEARCH
	, EXPORTS
	, EXTERNAL_CHAT
	, EXTERNAL_OPEN
	, MESSAGES
	, REVIEW
	, REVIEW_ERRAND
	, SEARCH
	, SEARCH_ANSWERS
	, STATISTICS
	, V5
	, WORKFLOW
	, EXPORTS_CALL
	, LIVE_REPORTS
	, KNOWLEDGE_BASES_CATEGORY_ID
	, KNOWLEDGE_BASES_QUESTION_ID
	, KNOWLEDGE_BASES_SUGGESTION_ID
} from '../../common/path';
import { showNewReceipt } from '../../common/constants';
import {
	CHATBOT_ROUTES,
	CHATBOT_ROUTES_MATCH
} from '../../common/v5/chatbotConstants';
import {
	RECEIPT_GREETING_ROUTES,
	RECEIPT_GREETING_ROUTES_MATCH
} from '../../common/v5/receiptGreetingConstants';
import { REVIEW_ROUTES } from '../../common/v5/reviewConstants';
import { SMTP_ROUTES, SMTP_ROUTES_MATCH } from '../../common/v5/smtpConstants';
import {
	A_CHATBOTS,
	A_SMTP,
	A_TEMPLATES_WHATSAPP,
	A_KNOWLEDGE_BASES
} from '../../common/v5/constants';
import { hasPrefix } from '../../common/v5/helpers';
import WorkflowCtnr from '../../views/v5/workflowCtnr';
import AdminCtnr from '../../views/v5/adminCtnr';
import InternalMsgListCtnr from '../../views/v5/internalMsgListCtnr';
import ExportCtnr from '../../views/v5/exportV5';
import { SearchAnswerCtnr, default as KnowledgeBaseCtnr } from '../../views/v5/knowledgebase/knowledgeBaseCtnr';
import SearchCtnr from '../../views/v5/searchCtnr';
import StatisticsCtnr, { LiveReportCtnr } from '../../views/v5/statisticsCtnr';
import { ExportCallCtnr } from "../../views/exports/exportCtnr";

const ADMIN_TEMPLATE_WHATSAPP = `${ADMIN}/templates/whatsapp`;

const AdminView = ({ match }) => {
	const adminView = match.params.view;
	var	id = match.params.id != undefined ? match.params.id : undefined;

	if (process.env.NODE_ENV !== 'production') {
		console.log('match params admin>', match);
	}
	if (adminView) {
		return <AdminCtnr view={adminView} routerView={adminView} id={id} />;
	} else if (matchPath(match.url, CHATBOT_ROUTES_MATCH)) {
		return <AdminCtnr view={A_CHATBOTS} routerView={A_CHATBOTS} />;
	} else if (matchPath(match.url, ADMIN_TEMPLATE_WHATSAPP)) {
		return <AdminCtnr view={A_TEMPLATES_WHATSAPP} routerView={A_TEMPLATES_WHATSAPP} />;
	} else if (showNewReceipt() &&
		matchPath(match.url, RECEIPT_GREETING_ROUTES_MATCH)) {
		const view = match.params[0];
		return <AdminCtnr view={view} routerView={view} />;
	} else if (matchPath(match.url, SMTP_ROUTES_MATCH)) {
		return <AdminCtnr view={A_SMTP} routerView={A_SMTP} />;
	} else if (matchPath(match.url, KNOWLEDGE_BASES_CATEGORY_ID)) {
		return <AdminCtnr view={A_KNOWLEDGE_BASES} routerView={A_KNOWLEDGE_BASES} subPath={"category"} id={id} />;
	} else if (matchPath(match.url, KNOWLEDGE_BASES_QUESTION_ID)) {
		return <AdminCtnr view={A_KNOWLEDGE_BASES} routerView={A_KNOWLEDGE_BASES} subPath={"question"} id={id} />;
	} else if(matchPath(match.url, KNOWLEDGE_BASES_SUGGESTION_ID)) {
		return <AdminCtnr view={A_KNOWLEDGE_BASES} routerView={A_KNOWLEDGE_BASES} subPath={"suggestion"} id={id} />;
	} else {
		return <NotFoundPage title={I('Page not found.')} />;
	}
};

const ExportView = ({ match }) => {
	if (process.env.NODE_ENV !== 'production') {
		console.log('match param export>', match);
	}
	return <ExportCtnr />;
};

const WorkflowView = ({ match }) => {
	var props = {
		pickUpNext: (match.params.extra != undefined && match.params.extra == "next" ? true : false),
		loadErrandId: 0,
		loadErrandHash: "",
		searchCtx: false,
		errandCtx: false,
		searchTerm: (match.params.searchTerm != undefined ? match.params.searchTerm : ""),
		current: (match.params.current != undefined ? match.params.current : ""),
	};
	if (process.env.NODE_ENV !== 'production') {
		console.log('match params> ', match);
	}
	switch (match.path) {
		case "/":
		case "/index.html":
		case "/ng/v5":
		case V5:
		case WORKFLOW:
		case REVIEW:
		case REVIEW_ERRAND:
			return <WorkflowCtnr {...props} />;
		case SEARCH:
			return <SearchCtnr {...props}/>;
		case `${BACKSEARCH}/:searchTerm/:current/:searchLabel`:
			return <SearchCtnr {...props}/>;
		case `${BACKSEARCH}/:current/:searchLabel`:
			return <SearchCtnr {...props}/>;
		case STATISTICS:
			return <StatisticsCtnr {...props} />;
		case EXPORTS_CALL:
			return <ExportCallCtnr {...props}/>;
		case LIVE_REPORTS:
			return <LiveReportCtnr {...props} />;
		case MESSAGES:
			return <InternalMsgListCtnr {...props} />;
		// case `${V5}/exports`:
		// 	return <ExportCtnr {...props} />;
		case `${V5}/open/search/:id`:
			props["loadErrandId"] = match.params.id;
			props["searchCtx"] = true;
			return <WorkflowCtnr {...props} />;
		case `${V5}/open/errand/:cipherKey/:id`:
			props["loadErrandId"] = match.params.id;
			props["loadErrandHash"] = match.params.cipherKey;
			props["errandCtx"] = true;
			return <WorkflowCtnr {...props} />;
		case `${V5}/open/collaboration/:id`:
			props["loadErrandId"] = match.params.id;
			props["errandCtx"] = true;
			return <WorkflowCtnr {...props} />;
		case `${V5}/viewerrand/:id`:
			props["loadErrandId"] = match.params.id;
			return <WorkflowCtnr {...props} />;
		case EXTERNAL_OPEN:
			if(externalqueue !== 'undefined' && externalqueue != null){
				externalqueue.isExternalOpen = true;
			}
			props["isExternal"] = true;
			return <WorkflowCtnr {...props} />;
		case SEARCH_ANSWERS:
			return <SearchAnswerCtnr />;
		default:
			return <NotFoundPage title={I('Page not found.')} />;
	}
}

const NotFoundPage = ({ title, children }) => (
	<div className="not-found-page-wrapper mt-4">
		<div className="not-found centered text-center">
			<div className="not-found-banner">
				{/* <h1>4<span>
					<img src="https://jira.cention.se:8443/secure/attachment/16838/404_Omnichannel-v2-20170511.png"/>
					</span>4
				</h1> */}
				<div className="row align-items-center">
					<div className="col not-found-text-banner p-0">4</div>
					<div className="col-6 p-0">
						<img className="not-found" src={"/img/not-found-page-zero-Omnichannel.png"}/>
					</div>
					<div className="col not-found-text-banner p-0">4</div>
				</div>
			</div>
			<h2 className="not-found-text-message my-4">{title}</h2>
			{children}
			<button className="btn-blue mt-4">
				<Link to={V5} className="text-white">{I('Go back to the main page')}</Link>
			</button>
		</div>
	</div>
);

const sorryEmailUs = I('We are sorry because the link you requested is broken. Please {EMAIL} us.'),
	emailSubject = I('Cention broken link.'),
	centionSupportEmail = "support@cention.com";

class ErrorEmailUs extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const { subject, body, email, message } = this.props,
			href = 'mailto:' + email + '?' + $.param({subject, body}),
			messageSplits = message.split('{EMAIL}');
		let ms = [];
		$.each(messageSplits, (i,v) => {
			ms.push(<span key={'m'+i}>{v}</span>);
			if (i < messageSplits.length - 1) {
				ms.push(<a key={'e'+i} href={href}>{I('email')}</a>);
			}
		});
		return (
			<p className="m-3" style={{fontSize:"large"}}>
				{ms}
				<span>&nbsp;{I('Email')}: {email}</span>
			</p>
		);
	}
}

class NoMatch extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			reload: null,
			time: 0
		};
	}
	componentDidMount() {
		if (!hasPrefix(this.props.location.pathname, V5)) {
			window.location.reload();
		} else {
			this.setState({reload: {reloading: false}, time: Date.now()});
		}
	}
	render() {
		if (!this.state.reload) {
			return null;
		}
		const { pathname, search, hash } = this.props.location
			, brokenLink = 'broken link: ' + pathname + search + hash
			, href = ' href: ' + window.location.href
			, timestamp = ' time: ' + this.state.time
			;
		return (
			<NotFoundPage title={I("Link doesn't match.")}>
				<ErrorEmailUs
					email={centionSupportEmail}
					subject={emailSubject}
					message={sorryEmailUs}
					body={brokenLink+href+timestamp}
				/>
			</NotFoundPage>
		);
	}
}

const ADMIN_VIEW = `${ADMIN}/:view`;
const ADMIN_VIEW_ID = `${ADMIN}/:view/:id`;

const ADMIN_ROUTES = CHATBOT_ROUTES
	.concat(RECEIPT_GREETING_ROUTES)
	.concat(SMTP_ROUTES)
	.concat(KNOWLEDGE_BASES_CATEGORY_ID)
	.concat(KNOWLEDGE_BASES_QUESTION_ID)
	.concat(KNOWLEDGE_BASES_SUGGESTION_ID)
	.concat([ADMIN_TEMPLATE_WHATSAPP, ADMIN_VIEW, ADMIN_VIEW_ID]);

const Routes = ({ ready, reviewEnabled, reviewFolderEnabled }) => {
	if(!ready) {
		return null;
	}
	return (
		<Switch>
			<Route path={'/index'} component={WorkflowView} />
			<Route path={'/index.html'} component={WorkflowView} />
			<Route path={SEARCH} component={WorkflowView} />
			<Route exact path={`${BACKSEARCH}/:searchTerm/:current/:searchLabel`} component={WorkflowView} />
			<Route exact path={`${BACKSEARCH}/:current/:searchLabel`} component={WorkflowView} />
			<Route path={MESSAGES} component={WorkflowView} />
			<Route exact path={ADMIN_ROUTES} component={AdminView} />
			<Route path={EXPORTS} component={ExportView}/>
			<Route path={EXPORTS_CALL} component={WorkflowView} />
			<Route path={SEARCH_ANSWERS} component={WorkflowView} />
			<Route path={WORKFLOW} component={WorkflowView} />
			<Route path={`${V5}/viewerrand/:id`} component={WorkflowView} />
			<Route path={EXTERNAL_OPEN} component={WorkflowView} />
			<Route path={EXTERNAL_CHAT} component={WorkflowView} />
			<Route path={`${V5}/open/search/:id`} component={WorkflowView} />
			<Route path={`${V5}/open/errand/:cipherKey/:id`} component={WorkflowView} />
			<Route path={`${V5}/open/collaboration/:id`} component={WorkflowView} />
			{(features["agent-data-overview-statistics"] ||
				features["admin.show-executive-report"] ||
				features["cention-reports"])
					&& <Route path={STATISTICS} component={WorkflowView} />
			}
			<Route path={LIVE_REPORTS} component={WorkflowView} />
			{reviewFolderEnabled
				&& <Route exact path={REVIEW_ROUTES} component={WorkflowView} />
			}
			<Route exact path={V5} component={WorkflowView} />
			<Route component={props => <NoMatch {...props} />} /> {/* NOTE 1 */}
		</Switch>
	);
};

export default Routes;
