import { darkTheme as c } from "../../components/v5/Theme"

export const STATISTIC_STYLES = `
  .statistics {
    .app-sidebar {
      .app-header {
        // background-color: ${c.lightBlack};
      }
    }
    .app-content {
      background-color: ${c.lightBlack};
      .app-header {
        // background-color: ${c.lightBlack};
        .c3-header-actions {
          >li {
            &.dropdown.white {
              background-color: ${c.darkGrey};
              &:hover {
                background-color: ${c.tintBlack};
              }
            }
          }
        }
      }
      .app-body{
        background-color: ${c.lightBlack};
      }
      .dropdown-menu {
        &.show {
          background-color: ${c.darkGrey};
          .dropdown-item {
            background-color: ${c.darkGrey} !important;
            &:hover {
              background-color: ${c.tintBlack};
            }
          }
        }
      }
      .label{
        color: ${c.lightBlack};
      }
      .text-field{
        background-color: ${c.grey};
      }
      .app-inner-content{
        background-color: ${c.lightBlack};
      }
    }
    .ReactTable {
      color: ${c.lightGrey};
    }
  }
  .create.report.popup .label-row .label-block .preview .sortable li {
    background-color: ${c.midGrey};
  }
  .schedule.report.popup .label-row .label-block .preview .sortable li {
    background-color: ${c.midGrey};
  }
  .react-grid-layout {
    color: ${c.grey};
  }
  .chart-link {
    color: ${c.grey};
  }
`