import { darkTheme as c } from "../../components/v5/Theme"

export const SEARCH_STYLES = `
  .search-page {
    .layout {
      .app-content {
        .app-inner-content {
          .errand-box-search {
            .search-block {
              &:hover {
                background: ${c.tintBlack};
              }
              .search-info {
                .errand-preview {
                  background-color: ${c.midGreyT2};
                  .errand-preview-subject {
                    background-color: ${c.lightBlack};
                  }
                }
              }
            }
            .search-pagination {
              background-color: ${c.lightBlack};
              li {
                .page-link {
                  background-color: unset;
                  &:hover {
                    background-color: ${c.tintBlack};
                  }
                }
              }
              .page-item {
                &.active {
                  page-link {
                    background-color: unset;
                  }
                }
                .page-link {
                  background-color: unset;
                  &:hover {
                    background-color: ${c.tintBlack};
                  }
                }
              }
            }
            .c3-square-checkbox{
              &.big {
                div.jq-checkbox__div {
                  border-color: ${c.midGrey};
                }
              }
            }
            div.jq-checkbox__div {
              border-color: ${c.midGrey};
            }
            .search-block-filter {
              .search-button {
                color: ${c.lightGrey};
              }
            }
          }
        }
      }
    }
  }
  .errand-box-closed-msg-container {
    .errand-closed-msg {
      background-color: ${c.lightBlack};
      .errand-closed-text {
        background-color: ${c.lightBlack};
      }
    }
  }
  .search-result-spinner {
    color: ${c.lightGrey};
  }
  .search-side-menu, .search-side-menu-collapse {
    .collapse-tooltip-menu {
      .collapse-menu-list li.current {
        background-color: ${c.darkGrey};
      }
    }
  }
`