import { darkTheme as c } from "../../components/v5/Theme"

export const CKE_STYLES = `
.cke_panel {
  background-color: ${c.darkGrey};
  .cke_colorblock {
    background-color: ${c.darkGrey};
  }
  a.cke_colorauto, a.cke_colormore {
    border-color: inherit;
  }
  a.cke_colorbox {
    border-color: transparent;
  }
}
.cke_panel_grouptitle {
  background-color: inherit;
}
.cke_dialog iframe.cke_pasteframe {
  background-color: ${c.midGrey};
}
`