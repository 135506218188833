import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { emptyObject } from '../../common/constants'
import { composeWithDisplayName } from '../../reactcomponents/hocs'
import {
  handlingBarHeight,
  headerSlimHeight,
  mobileFixedFooterHeight,
  mobileMainHeaderHeight
} from '../_variables'

const getHeaderHeight = (smallOrLarge) => {
  if (smallOrLarge.small) {
    return mobileMainHeaderHeight
  }
  return headerSlimHeight
}

const getFooterHeight = (smallOrLarge) => {
  if (smallOrLarge.small) {
    return mobileFixedFooterHeight
  }
  return handlingBarHeight
}

const useHeaderFooterHeight = () => {
  const { breakpoints } = useTheme()
  const small = useMediaQuery(breakpoints.down('sm'))
  const large = useMediaQuery(breakpoints.up('xl'))
  return useMemo(() => {
    const smallOrLarge = { small, large }
    return {
      footerHeight: getFooterHeight(smallOrLarge),
      headerHeight: getHeaderHeight(smallOrLarge)
    }
  }, [small, large])
}

const withHeaderFooterHeightBase = Component => ({ ...props }) => (
  <Component
    {...useHeaderFooterHeight()}
    {...props}
  />
)

// This HOC provides responsive aware header and footer of C3. Any CSS that
// change the C3 v5 app header or footer MUST reflect on this HOC.
export const withHeaderFooterHeight = composeWithDisplayName(
  'withHeaderFooterHeight',
  withHeaderFooterHeightBase
)
