import React from 'react'
import styled from 'styled-components'
import { isChartGrid } from '../common/v5/helpers'
import { mergeStyleIfExist } from '../reactcomponents/hocs'

const block = {
  height: '100%',
  overflow: 'auto',
  width: '100%'
}

export const withReactGridLayoutItem = Component => ({
  layout
  , style
  , ...props
}) => {
  if (isChartGrid(layout)) {
    return <Component style={mergeStyleIfExist(style, block)} {...props} />
  }
  return <Component style={style} {...props} />
}

export const PointerItalic = styled.i`
  cursor: pointer
`
