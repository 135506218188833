import { darkTheme as c } from "../../components/v5/Theme"

export const KNOWLEDGEBASE_STYLES = `
  #knowledge-base-wrapper {
    .list-form {
      .card {
        background-color: ${c.darkGrey};
      }
    }
  }
  .toggle-btn {
    color: ${c.lightWhite};
    &:after {
      border-color: inherit;
      border-width: 2px;
    }
  }
  .knowledgebase-tree-container {
    .list-container {
      background-color: ${c.darkGrey};
    }
  }
  .knowledgebases-preview {
    .tooltip{
      &.show {
        background-color: ${c.midGreyT2};
      }
      .tooltip-inner {
        background-color: inherit;
        .knowledgebases-preview-title {
          background-color: ${c.lightBlack};
        }
      }
    }
  }
.errand-box-wrapper.v5 .errand-box-body .SplitPane .Pane .panel-one .content-wrap .right-sidepanel .panel-header {
  background-color: ${c.lightBlack};
}
.general-popup-modal-backdrop.popup .popup-inner .popup-title {
  background-color: ${c.lightBlack};
}
.bQfsSf.adminWrapper #knowledge-base-wrapper {
  background-color: ${c.darkGrey};
}
.general-popup-modal-backdrop.popup .popup-inner.kb-manual-errands-modal .popup-content {
  background-color: ${c.darkGrey};
}
#knowledge-base-wrapper #DragDropKanban.knowledge-base .board__container .board__body {
  background-color: ${c.midGreyT2};

}

#DragDropKanban #kb-library-panel{
  .kb-expandable-area{
   background-color: ${c.lightBlack};
  }
  .kb-file-btns {
  background-color: ${c.lightBlack};
  }
}
`