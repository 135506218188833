import { darkTheme as c } from "../../components/v5/Theme"

export const WORKFLOWTABLE_STYLES = `
.wf-table-container-class {
	.wf-table-header-container {
		table.wf-header-borderless {
      thead {
        tr {
          &:hover {
            .resizable-highlight {
              border-right-color: ${c.midGrey};
            }
          }
          th {
            color: ${c.lightWhite};
            border-color: ${c.midGrey};
          }
        }
      }
    }
  }
}
table.wf-body-class > tbody {
  tr {
    td {
      border-color: ${c.darkGrey};
      color: ${c.lightGrey};
    }
  }
}
.wf-pagination-page {
  .pagination {
    .active {
      .page-link {
        background-color: ${c.darkGrey};
      }
    }
  }
  .page-link {
    background-color: ${c.darkGrey};
  }
}
.wf-pagination {
  color: ${c.lightGrey};
}
`