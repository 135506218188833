import { darkTheme as c } from "../../components/v5/Theme"

export const REPLY_BOX_STYLES = `
.errand-box-reply {
  input {
    background-color: ${c.darkGrey};
    &:focus {
      background-color: ${c.darkGrey};
    }
  }
  .filterheader {
    background-color: ${c.darkGrey};
  }
  .filterheader + .scrollable {
    background-color: ${c.darkGrey};
    input[type=checkbox]:checked + label:after {
			filter: invert(1);
		}
	}
  .to-label, .to-input, .cc-label, .cc-input, .bcc-label, .bcc-input {
    background-color: ${c.darkGrey};
    color: ${c.lightGrey};
  }
  .subject-wrapper {
    .label {
      background-color: ${c.darkGrey};
    }
  
    .subject-input {
  
      input[type="text"] {
        background-color: ${c.darkGrey};
        color: ${c.grey};
      }
    }
  }
  
  .react-tagsinput-tag {
    color: ${c.lightWhite};
  }
  .btn-fileupload {
    background-color: ${c.lightWhite};
    &:hover {
      background-color: ${c.pureWhite};
    }
  }
  .c3-toolbar-wrapper {
    background-color: ${c.darkGrey};
    color: ${c.lightGrey};
  }
  iframe {
    background: ${c.darkGrey};
  }
}
.errand-box-reply-switcher {
  color: ${c.lightGrey};
}
.errand-box-reply-forms {
  .tabs-option-container {
    color: ${c.lightGrey};
  }
}
.block-reply-nav.v5 {
  color: ${c.lightGrey};
}
.replyOptionBtn {
  color: ${c.lightGrey};
  border-color: ${c.midGreyT2};
  &:hover {
    border-color: ${c.midGrey};
  }
}
.cke_toolgroup {
  background: ${c.midGreyT2};
}
a.cke_button {
  background-color: ${c.midGreyT2};
  filter: saturate(2);
}
a.cke_button_on {
  background-color: ${c.tintBlue};
}
.cke_combo_off {
  .cke_combo_button {
    background: ${c.midGreyT2};
    &:hover {
      background: ${c.tintBlue};
    }
  }
}
.cke_top {
  background: ${c.tintBlack} none repeat scroll 0 0;
  border-color: ${c.tintBlack};
}
.cke_wysiwyg_frame, .cke_wysiwyg_div {
  background: ${c.tintBlack};
}

.cke_dialog_body {
  background-color: ${c.darkGrey};
  border-color: ${c.tintBlack};
  label {
    color: ${c.lightGrey};
  }
}
.cke_dialog_title {
  border-color: ${c.tintBlack};
}
.cke_dialog_contents {
  border-color: ${c.tintBlack};
}
.cke_dialog_footer {
  border-color: ${c.tintBlack};
}
div.cke_dialog_ui_input_text,
div.cke_dialog_ui_input_password {
  padding: 0;
  border-color: ${c.tintBlack};
  background-color: ${c.lightBlack};
}
input.cke_dialog_ui_input_text,
input.cke_dialog_ui_input_password {
  background-color: ${c.lightBlack};
  color: ${c.lightGrey};
}
select.cke_dialog_ui_input_select {
  color: ${c.lightGrey};
  background-color: ${c.lightBlack};
  border-color: ${c.tintBlack};
  option {
    color: ${c.lightGrey};
  }
}
.signature-preview {
  background-color: ${c.darkGrey};
  color: ${c.lightGrey};
}
vertical .attachment-panel {
  background-color: ${c.midGreyT2};
}
.attachment-panel {
  background-color: ${c.darkGrey};
}
.attachment-panel-toggler {
  & button {
    background-color: ${c.midGreyT2};
  }
}
.attachment-panel .attachment-panel__body [class*=-single-item] {
  border-color: ${c.lightBlack};
  .item-image > i {
    background-color: ${c.midGreyT2};
  }
}
.filestosend-v5 {
  background-color: ${c.darkGrey};
  color: ${c.midGrey};
  .errand-box-attachments-agent-single-item {
    background-color: ${c.lightBlack};
  }
}
`