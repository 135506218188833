import { darkTheme as c } from "../../components/v5/Theme"

export const SIDEBAR_STYLES = `
.sidebar-settings-list {
  .sidebar-settings-chat {
    background-color: ${c.darkGrey};
  }
}
.agent-side-menu{
  .header-user-status {
    .dropped .jq-selectbox__dropdown {
      background: ${c.darkGrey};
      li {
        color: ${c.lightGrey};
        border-color: ${c.lightBlack};
      }
    }
  }
}

.errand-box-wrapper.v5 {
  background-color: ${c.darkGrey};
  .right-sidepanel {
    background-color: ${c.darkGrey};
    color: ${c.lightGrey};
    &.internal-message {
      .panel-header {
        background-color: ${c.tintYellow};
        color: ${c.lightWhite};
      }
    }
    &.collaboration {
      .panel-header {
        background-color: ${c.tintBlue};
        color: ${c.lightWhite};
      }
      .panel-body {
        .participants {
					background-color: ${c.tintBlack};
				}
        .message-group {
          background-color: ${c.darkGrey};
          .icPanelMsg {
            &.collab-query {
              background-color: steelblue;
            }
            &.collab-answer {
              background-color: slategrey;
            }
          }
        }
      }
    }
  }
}
.icPanelMsg {
  background-color: ${c.midGreyT2};
  color: ${c.lightWhite};
}
.errand-box-chatmessages {
  .icPanel.chat {
    background-color: ${c.darkGrey};
    .icPanelHeader {
      background-color: ${c.tintYellow};
      color: ${c.lightWhite};
    }
  }
}
.collapse-tooltip-menu {
  .header-notices .dropped .header-dropdown {
    background-color: ${c.darkGrey};
  }
}
.header-notices {
  .header-dropdown .notices-list-action {
    color:${c.lightGrey};
  }
}
.sidebar-nav-select.jq-selectbox.dropdown.opened {
  .jq-selectbox__select-text {
    color: ${c.lightGrey};
  }
}
.conversations-sort-select {
  .jq-selectbox__select {
    color: ${c.lightGrey};
  }
  .jq-selectbox__dropdown {
    background-color: ${c.darkGrey};
    ul li {
      color: ${c.lightGrey};
    }
  }
}
.sidebar-menu {
  li:hover {
    color: ${c.light};
  }
}
.sidebar-trigger {
  color: ${c.lightGrey};
  &:hover {
    color: ${c.lightGrey};
  }
}
.app-sidebar.collapsed .app-inner-content .flex-menus-wrap {
  i {
    color: ${c.lightGrey};
  }
  .collapse-tooltip-folders, .collapse-tooltip-menu {
    background-color: ${c.darkGrey};
  }
}
`