import { inputSpace } from '../styles/_variables'

const defaultDirection = 'row'

const flexDirectionToMarginDirection = {
  [defaultDirection]: 'left',
  'row-reverse': 'right',
  column: 'top',
  'column-reverse': 'bottom'
}

const marginFromDirection = ({ direction }) => {
  const marginDirection = flexDirectionToMarginDirection[direction]
  if (marginDirection) {
    return marginDirection
  }
  return flexDirectionToMarginDirection[defaultDirection]
}

const flexDirection = ({ direction }) => {
  if (direction) {
    return direction
  }
  return defaultDirection
}

// flexbox provides the css to center component horizontally (default) or
// vertically using css flex-box.
const flexbox = props => `
  display: flex;
  flex-direction: ${flexDirection(props)};
  justify-content: center;
  align-items: center;
  > * + * {
    margin-${marginFromDirection(props)}: ${inputSpace};
  }
`
export default flexbox

export const createCapitalize = propName => props => {
  if (props[propName]) {
    return 'text-transform: capitalize;'
  }
  return ''
}
