import React, { useMemo } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { withHeaderFooterHeight } from './app'
import {
  composeWithDisplayName,
  mergeStyleIfExist
} from '../../reactcomponents/hocs'
import { mobileAppHeaderHeight } from '../_variables'

const minus = height => ' - ' + height

const adjustMobileMarginTop = isSmall => {
  if (isSmall) {
    return minus(mobileAppHeaderHeight)
  }
  return ''
}

const withDynamicHeight = Component => ({
  footerHeight,
  headerHeight,
  style,
  ...props
}) => {
  // TODO: refactor this to use useBinaryBreakpoint
  const { breakpoints: { down } } = useTheme()
  const isSmall = useMediaQuery(down('sm'))
  let offsetC3Height = "0px";
  const heightStyle = useMemo(
    () => ({
      height: 'calc(100vh' +
        minus(offsetC3Height) +
        minus(footerHeight) +
        adjustMobileMarginTop(isSmall) +
        ')'
    }),
    [footerHeight, offsetC3Height, isSmall]
  )
  return <Component style={mergeStyleIfExist(style, heightStyle)} {...props} />
}

export const withOverviewContentHeight = composeWithDisplayName(
  'withOverviewContentHeight',
  withHeaderFooterHeight,
  withDynamicHeight
)
