import { darkTheme as c } from "../../components/v5/Theme"

export const LAYOUT_STYLES = `
.layout {
  .app-sidebar {
    .app-inner-content {
      background-color: ${c.midGreyT2};
      .flex-menus-wrap {
        .side-bar-main-menu {
          .sidebar-nav-select.jq-selectbox.dropdown.opened {
            background-color: ${c.darkGrey};
          }
        }
      }
      .sidebar-settings-list {
        ul {
          color: ${c.lightGrey};
        }
      }
    }
  }

  .app-header {
    background-color: ${c.darkGrey};
    // i {
    //   color: ${c.lightGrey};
    // }
  }

  .app-conversations {
    .app-inner-content {
      background-color: ${c.midGrey};
    }
    .conversation {
      background-color: ${c.midGreyT2};
    }
    .grid-menu {
      background-color: ${c.midGrey};
      border-bottom: 1px solid ${c.tintLightBlack};
      .carousel-item {
        .item-wrapper {
          li > a {
            background-color: ${c.midGreyT2};
          }
        }
      }
    }
  }

  .app-content {
    .app-inner-content {
      background-color: ${c.midGreyT2};
    }
    .app-workflow-actions {
      background-color: ${c.midGreyT2};
    }
    .breadcrumb {
      .breadcrumb-item {
        .main-menu .jq-selectbox__dropdown>.nav-menu {
          background-color:${c.lightBlack};
          li#administration .nav-sub-menu {
            background-color:${c.lightBlack};
          }
        }
      }
      .folder-breadcrumb .breadcrumb-dropdown {
        background-color: ${c.lightBlack}
      }
    }
  }
  .app-content.width-100 {
    .app-inner-content {
      background-color: ${c.lightBlack};
    }
  }
}

.main-footer-section {
  .handling-bar {
    background-color: ${c.midBlack};
    >ul li {
      color: ${c.lightGrey};
    }
  }

  .internal-chat {
    background-color: ${c.darkGrey};
    .internal-chat-header {
      background-color: ${c.midGreyT2};
      color: ${c.pureWhite};
    }
    .internal-chat-content {
      .internal-chat-users {
        a {
          color: ${c.lightGrey};
          &:hover {
            color: ${c.pureWhite};
          }
        }
      }
      .internal-chat-conversation-header {
        background-color: ${c.midGreyT2};
      }
      .internal-chat-conversation {
        .internal-chat-conversation-messages {
          background-color: ${c.lightBlack};
          .message-system {
            background-color: ${c.midGrey};
          }
          .message-textbox {
            background-color: ${c.darkGrey};
            color: ${c.light};
            &:before {
              border-right-color:${c.darkGrey};
            }
          }
          .message.my {
            .message-textbox {
              background-color: ${c.aqua};
              &:before {
                border-top-color: ${c.aqua};
                border-right-color: ${c.aqua};
              }
            }
          }
          .message-name {
            color: ${c.lightGrey};
          }
        }
        .internal-chat-conversation-message {
          border-color: ${c.midGrey};
          textarea {
            background-color: ${c.darkGrey};
            color: ${c.light};
          }
        }
      }
    }
  }
}
`