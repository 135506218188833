import { darkTheme as c } from "../../components/v5/Theme"

export const SEARCH_ANSWER_STYLES = `
  .layout {
    .app-content {
      &.search-answers {
        .app-inner-content {
          background-color: ${c.lightBlack};
        }
      }
    }
  }
`