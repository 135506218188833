import { darkTheme as c } from "../../components/v5/Theme"

export const UI_STYLES = `
  .toggle-switch .jq-checkbox__div {
    background-color: ${c.lightBlack};
    border-color: transparent !important;
  }
  .tooltip-inner {
    background-color: ${c.darkGrey};
  }
  .tooltip-inner.collapse-tooltip-menu {
    background-color: ${c.darkGrey};
    .collapse-menu-list li span {
      color: ${c.lightGrey};
    }
    .header-notices .dropped .header-dropdown .notices-list-item-icon i {
      color: ${c.lightGrey};
    }
  }
  .tooltip-without-before .tooltip-content, [data-tooltip] .tooltip-content {
    background-color: ${c.lightBlack};
  }
  [data-tooltip] {
    .tooltip-content:before {
      border-bottom-color: ${c.lightBlack};
    }
  }
  [data-dropdown-menu] .dropdown-menu {
    background-color: ${c.lightBlack};
    &:before {
      border-bottom-color: ${c.lightBlack};
    }
  }
  .content-preview {
    .tooltip-inner {
      background-color: ${c.midGreyT2};
    }
  }
  .moment-datetime {
    color: ${c.lightWhite};
  }
  .scroll-to-top {
    background-color: ${c.lightBlack};
  }
  .card {
    background-color: inherit;
  }
  .helper {
    .fa-question-circle {
      color: ${c.midGrey};
    }
  }
  .page-link {
    background-color: ${c.midGreyT2};
  }
  .page-item.disabled .page-link {
    background-color: ${c.midGreyT2};
  }
  .shift-list-container .section-col .shift.list-section .c3-dropdown {
    background-color: ${c.lightBlack};
  }
  .nav-tabs {
    .nav-link:focus, .nav-link:hover {
      border-color: ${c.tintLightBlack} ${c.tintLightBlack} ${c.tintBlack};
    }
    .nav-link.active{
      color: ${c.centionBlue};
      border-color: ${c.lightBlack};
    }
  }
  ::-webkit-scrollbar {
    background-color: unset;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${c.midGrey};
    -webkit-box-shadow: inset 0 0 3px ${c.tintBlack};
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px ${c.tintBlack};
    background-color: ${c.lightBlack};
  }
  ::-webkit-scrollbar-corner {
    background-color: ${c.tintBlack};
  }
  .popup {
    .popup-inner {
      background-color: ${c.darkGrey};
    }
    .popup-acquire-inner {
      background-color: ${c.lightBlack};
      .popup-acquired-errands-dropdown {
        background-color: ${c.midGreyT2};
      }
    }
    .conversation {
      background-color: ${c.lightBlack};
    }
  }
  .manual.errand.popup {
    .caller-id {
      background-color: ${c.lightBlack};
    }
    .call-dialpad {
      background-color: ${c.lightBlack};
    }
  }
  .error-modal {
    background-color: ${c.lightBlack};
    .err-head {
      color: indianred;
    }
    .err-body {
      .error-details {
        #moreErrorDetails {
          background-color: ${c.darkGrey};
          .details-container {
            p {
              color: inherit;
            }
          }
        }
      }
    }
  }
  .modal-content {
    background-color: ${c.lightBlack};
    color: ${c.lightGrey};
  }
  .tag-filter-top {
    background-color: ${c.darkGrey};
  }
  .react-datepicker {
    background-color: ${c.midGreyT2};
    border-color: ${c.tintBlack};
  }
  .react-datepicker__header {
    background-color: ${c.darkGrey};
    border-color: ${c.tintBlack};
    .react-datepicker__current-month {
      color: ${c.lightGrey};
    }
  }
  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${c.lightGrey};
  }
  .react-datepicker__day:hover {
    background-color: ${c.tintBlack};
  }
  .react-datepicker-popper[data-placement^=bottom] {
    .react-datepicker__triangle {
      border-bottom-color: ${c.darkGrey};
      &:before {
        border-bottom-color: ${c.darkGrey};
      }
    }
  }
  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow:before,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow:before,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow:before {
    border-top-color: ${c.midGreyT2};
  }
  .errand-preview .tooltip .tooltip-inner {
    background-color: ${c.midGreyT2};
  }
  .cention-modal .modal-content {
    background-color: ${c.darkGrey};
  }
  .cention-video-agent {
    background-color: ${c.lightBlack};
    color: ${c.lightGrey};
  }
  .cell-preview {
    .tooltip {
      .tooltip-inner {
        background-color: ${c.lightBlack};
      }
    }
  }
  .shift-list-container {
    .section-col {
      .shift.list-section {
        div[role=menu].dropdown-menu a[role=menuitem].item>span {
          color: ${c.lightGrey}!important;
        }
      }
    }
  }
`