import React from 'react'
import { withContentRect } from 'react-measure'
import { useFitCount } from '../hooks/state'
import { composeWithDisplayName } from '../reactcomponents/hocs'

const buffer = 50

const withOverflowControlBase = Component => ({
  debug,
  contentRect: { client: { width }, scroll: { width: scrollWidth } },
  itemSize,
  availableWidth,
  ...props
}) => (
  <Component
    fitCount={useFitCount(
      buffer,
      availableWidth,
      width,
      scrollWidth,
      itemSize,
      buffer,
      debug
    )}
    {...props}
  />
)

// require: availableWidth parent remaining width that allow this component to
// grow.
const withOverflowControl = composeWithDisplayName(
  'withOverflowControl',
  withContentRect(['client', 'scroll']),
  withOverflowControlBase
)

export default withOverflowControl
