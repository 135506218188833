import { darkTheme as c } from "../../components/v5/Theme"

export const EXPORT_STYLES = `
  .export-page {
    .layout {
      .app-content {
        .app-inner-content {
          background-color: ${c.midGreyT2};
          .exportv5-wrapper {
            .export-content-wrapper {
              .export-edit {
                color: ${c.grey};
                .export-inner-wrapper {
                  .panel-heading {
                    color: ${c.lightGrey};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`